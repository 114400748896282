import { Injectable, signal } from "@angular/core";
import { IEmployee, IProgress, ITask } from "./schedule.interface";

@Injectable({ providedIn: "root" })
export class ScheduleStore {
  readonly stateTimes = signal<string[]>([]);
  readonly stateTimesHeader = signal<string[]>([]);
  readonly stateTasks = signal<ITask[]>(MOCK_DATA_TASK);
  readonly stateEmployees = signal<IEmployee[]>(MOCK_DATA_EMPLOYEE);
  readonly stateProgress = signal<IProgress[]>([]);

  resetStateTask(): void {
    this.stateTasks.set([]);
  }

  resetStateEmployee(): void {
    this.stateEmployees.set([]);
  }

  updateTask = (id: number, changes: ITask) => {
    return this.stateTasks.update(state => {
      const existing = state.find(x => x.taskId == id);
      if (!existing) return state;

      return [...state.filter(x => x.taskId != id), changes];
    });
  };

  addNewProgress(progressData: IProgress) {
    this.stateProgress.update(state => {
      return [...state, progressData];
    });
  }

  removeProgress(status: string) {
    this.stateProgress.update(state => {
      return [...state.filter(x => x.id != status)];
    });
  }

  resetTimeState() {
    let times = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        const hour = i % 12 === 0 ? 12 : i % 12;
        const period = i < 12 ? "AM" : "PM";
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        const formattedMinute = j < 10 ? `0${j}` : `${j}`;
        times.push(`${formattedHour}:${formattedMinute} ${period}`);
      }
    }
    times.push("12:00 AM");
    this.stateTimes.set(times);
  }

  resetTimeHeaderState() {
    let times = [];
    for (let i = 0; i <= 24; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const period = i < 12 ? "AM" : "PM";
      const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
      times.push(`${formattedHour}:00 ${i == 24 ? "AM" : period}`);
    }
    this.stateTimesHeader.set(times);
  }
}

export const MOCK_DATA_EMPLOYEE: IEmployee[] = [
  {
    imgUrl:
      "https://pics.craiyon.com/2023-10-25/b65f72d6d11a48c1bc560059cc36e31f.webp",
    id: "123",
    name: "Gabriel Montez",
    role: "Residential Security System Engineer",
    freeTime: 10,
    workHour: [
      "08:00 AM",
      "09:00 AM",
      "10:00 AM",
      "11:00 AM",
      "12:00 PM",
      "01:00 PM",
      "02:00 PM",
      "03:00 PM",
      "04:00 PM",
      "05:00 PM",
    ],
  },
  {
    imgUrl:
      "https://pics.craiyon.com/2023-10-25/b65f72d6d11a48c1bc560059cc36e31f.webp",
    id: "233",
    name: "Rommy",
    role: "Residential Security System Engineer",
    freeTime: 10,
    workHour: [
      "08:00 AM",
      "09:00 AM",
      "10:00 AM",
      "11:00 AM",
      "12:00 PM",
      "01:00 PM",
      "02:00 PM",
      "03:00 PM",
      "04:00 PM",
      "05:00 PM",
    ],
  },
];

export const MOCK_DATA_TASK: ITask[] = [
  {
    taskId: 1,
    employeeId: "123",
    startTime: "2024-07-21T02:00:00.000Z",
    endTime: "2024-07-21T03:30:00.000Z",
    task: "System Upgrade",
    detailTask: "Upgrading the server OS to the latest version.",
    customerName: "Mark Gold",
    house: "1234 Anesee Lake",
    location: "9679 46th Ave SW, WA, USA",
    dueDate: "12/07/2024",
    createdDate: "12/07/2024",
  },
  {
    taskId: 2,
    employeeId: "233",
    startTime: "2024-07-21T06:00:00.000Z",
    endTime: "2024-07-21T09:00:00.000Z",
    task: "Database Backup",
    detailTask: "Performing a full backup of the database.",
    customerName: "Mark Zuckerberg",
    house: "4433 Tenese",
    location: "9679 Texas USA",
    dueDate: "12/07/2024",
    createdDate: "12/07/2024",
  },
];
