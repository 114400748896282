import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ScheduleService {
  constructor() {}

  formatTimeTo12Hour(dateString: string): string {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Extract hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine AM/PM period
    const period = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format hours and minutes to always have two digits
    const hoursFormatted = hours < 10 ? `0${hours}` : hours;
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

    // Return formatted time
    return `${hoursFormatted}:${minutesFormatted} ${period}`;
  }

  calculateTotalHours(startTime: string, endTime: string) {
    const startTimeDate = new Date(startTime);
    const endTimeDate = new Date(endTime);

    const diffMilliseconds = endTimeDate.getTime() - startTimeDate.getTime();

    const totalHours = diffMilliseconds / (1000 * 60 * 60);

    return totalHours;
  }

  convertToDate(dateValue: any, timeValue: string) {
    const [time, period] = timeValue.split(" ");
    let [hours, minutes] = time.split(":");

    let numHours = +hours;
    if (period === "PM" && numHours !== 12) {
      numHours += 12;
    } else if (period === "AM" && numHours === 12) {
      numHours = 0;
    }

    // Mengubah jam dan menit pada objek Date
    dateValue.setHours(numHours);
    dateValue.setMinutes(minutes);
    dateValue.setSeconds(0);
    dateValue.setMilliseconds(0);
    return dateValue;
  }

  setSpecificTime(dateString: string, newTime: string): string {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Extract hours and minutes from newTime
    const timeParts = newTime.match(/(\d+):(\d+) (\w+)/);
    if (!timeParts) {
      throw new Error("Invalid time format");
    }

    let hours = parseInt(timeParts[1], 10);
    const minutes = parseInt(timeParts[2], 10);
    const period = timeParts[3];

    // Convert to 24-hour format
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    // Set the new hours and minutes
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);

    // Convert back to ISO string
    return date.toISOString();
  }

  formatAMPM(date: Date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minutesstring = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutesstring + " " + ampm;
    return strTime;
  }

  count30MinIntervals(startTime: string, endTime: string) {
    const start: Date = new Date(startTime);
    const end: Date = new Date(endTime);

    const timeDifference: number = end.getTime() - start.getTime();

    const intervals: number = Math.floor(timeDifference / (30 * 60 * 1000));

    return intervals;
  }

  getMinutesDifference(
    dateStr1: string | null,
    dateStr2: string | null
  ): number {
    if (dateStr1 === null || dateStr2 === null) {
      return 0;
    }
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    const millisecondsDiff = Math.abs(date1.getTime() - date2.getTime());

    const minutesDiff = millisecondsDiff / (1000 * 60);

    return minutesDiff;
  }
}
